<template>
  <div class="procureReportMonthly">
    <h1>采购合同执行日报</h1>
    <el-form :inline="true">
      <el-form-item label="采购月份">
        <el-date-picker v-model="searchParam.dates" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
      </el-form-item>
      <el-form-item label="供应商">
        <el-select v-model="searchParam.supplier" filterable style="width: 180px;" clearable>
          <el-option v-for="supplierItem in show.supplierList" :key="supplierItem.id" :label="supplierItem.value" :value="supplierItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="单价过滤">
        <el-input-number :controls="false" :precision="2" v-model="searchParam.productPrice" style="width: 120px;" />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" border show-summary :summary-method="summary" :span-method="handleSpanMethod" height="calc(100vh - 310px)">
      <el-table-column prop="date" label="采购日期" width="100" show-overflow-tooltip />
      <el-table-column prop="productPrice" label="单价" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.productPrice) }}
        </template>
      </el-table-column>
      <el-table-column prop="productQuantity" label="取货数量" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.productQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="productAmount" label="货款" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.productAmount) }}
        </template>
      </el-table-column>
      <el-table-column prop="payAmount" label="预付款" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.payAmount) }}
        </template>
      </el-table-column>
      <el-table-column prop="residueAmount" label="剩余款" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.residueAmount) }}
        </template>
      </el-table-column>
      <el-table-column prop="invoiceQuantity" label="开票数量" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.invoiceQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="invoiceAmount" label="开票金额" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.invoiceAmount) }}
        </template>
      </el-table-column>
      <el-table-column prop="productUnInvoiceQuantity" label="取货未开票数量" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.productUnInvoiceQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="productUnInvoiceAmount" label="取货未开票金额" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.productUnInvoiceAmount) }}
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import util from '../../utils/util'
import math from '../../utils/math'

const data = reactive({
  list: [],
  productQuantity: null,
  productAmount: null,
  payAmount: null,
  invoiceQuantity: null,
  invoiceAmount: null,
})

const show = reactive({
  selected: [],
  supplierList: []
})

api.get('/backend/dict/getByCode', { params: { code: 'supplier' } }).then(res => {
  show.supplierList = res.dictList
})
const now = new Date()
const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.dates = [firstDayOfMonth, now]
  searchParam.supplier = null
  searchParam.productPrice = null
}
resetSearchParam()

const commitFindList = () => {
  if (!searchParam.supplier) {
    ElMessage.error('请选择供应商')
    return
  }
  data.list = []
  const params = {}
  params.supplier = searchParam.supplier
  if (searchParam.productPrice) {
    params.productPrice = searchParam.productPrice
  }
  if (searchParam.dates && searchParam.dates.length == 2) {
    params.dateGe = util.parseTime(searchParam.dates[0], '{y}-{m}-{d}')
    params.dateLe = util.parseTime(searchParam.dates[1], '{y}-{m}-{d}')
  } else {
    ElMessage.error('请选择时间')
    return
  }
  api.get('/backend/statistics/procureAccumulateReportBySupplier', { params: params }).then(res => {
    data.list = res.list
    data.productQuantity = res.productQuantity
    data.productAmount = res.productAmount
    data.payAmount = res.payAmount
    data.invoiceQuantity = res.invoiceQuantity
    data.invoiceAmount = res.invoiceAmount
  })
}

const summary = () => {
  return ['合计', '', math.formatNumber(data.productQuantity), math.formatNumber(data.productAmount), math.formatNumber(data.payAmount), '', math.formatNumber(data.invoiceQuantity), math.formatNumber(data.invoiceAmount), '']
}

const handleSpanMethod = ({ row, columnIndex }) => {
  const columnIndexs = [1, 2, 3] // 不合并
  if (columnIndexs.includes(columnIndex)) {
    return [1, 1]
  }
  if (row.col == 0) {
    return [0, 0]
  }
  return [row.col * 1, 1]
}
</script>

<style lang="less"></style>